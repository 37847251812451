@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Oswald:wght@200&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom right,#051622, #050a0e);
  min-height: 100vh;
  /* margin-bottom: -50px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loading_icon {
  border: 10px solid #deb992; 
  border-top: 10px solid transparent;
  border-radius: 50%;
  width: 6em;
  height: 6em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading_container {
  margin-top: 10vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  background: transparent;
}

.nav_link_show {
  display: inline;
}

.show_small {
  display: none;
}

li {
  float: left;
}

li a {
  display: block;
  color: #deb992;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  color: white;
}

.active {
  color: #1ba098;
}

@media screen and (max-width: 600px) {
  ul li.right, 
  ul li {float: none;}

  .nav_hide_small {
    display: none;
  }

  .nav_link_hide {
    display: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: #333;
    width: 75vw;
    font-size: 1.5em;
    background: #deb992;
  }

  li a {
    color: #051622;
  }

  .active {
    color: white;
  }

  .show_small {
    display: inline;
  }
}

a:hover {
  color: black;
}

a:hover {
  color: white;
}

a:link {
  text-decoration: none;
}

.footer_relative {
  position: relative;
  bottom: 0;
  width: 100%;
  color: #deb992;
  text-align: center;
  z-index: -1;
}

.footer_fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #deb992;
  text-align: center;
  z-index: -1;
}

@media screen and (max-height: 800px), (max-width: 600px) {
  .footer_fixed {
    position: relative;
    bottom: 0;
    width: 100%;
    color: #deb992;
    text-align: center;
    z-index: -1;
  }
}

.name_main {
  padding-top: 20vh;
  color: #deb992;
  text-align: center;
  font-size: 5em;
  font-family: 'Montserrat', sans-serif;
}

.credentials {
  font-size: 0.6em;
  font-family: 'Oswald', sans-serif;
}

.page_body {
  margin: auto;
  width: 60%;
}

@media screen and (max-width: 1400px) {
  .page_body {
    margin: auto;
    width: 80%;
  }
}

.gold {
  color: #deb992;
}

.top_buffer {
  margin-top: 60px;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.char_right {
  float: right;
  clear: both;
}

.char_left {
  float: left;
  clear: both;
}

.image-gallery {
  width: 40vw;
  height: auto;
}

@media screen and (max-width: 1000px) {
  .image-gallery {
    width: 80vw;
    height: auto;
  }
}

.page_header {
  color: #deb992;
  text-align: left;
  margin-left: -.1em;
  font-size: 4em;
  font-family: 'Montserrat', sans-serif;
}

.loading_text {
  color: #deb992;
  font-size: 2.5em;
  font-family: 'Montserrat', sans-serif;
}

.media_panel_header {
  color: #deb992;
  text-align: left;
  font-size: 3em;
  font-family: 'Montserrat', sans-serif;
}

.fa_resizer {
  font-size: 4em;
}

@media screen and (max-width: 600px) {
  .page_header {
    color: #deb992;
    text-align: left;
    margin-left: -.1em;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
  }

  .media_panel_header {
    color: #deb992;
    text-align: left;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
  }

  .fa_resizer {
    font-size: 2.5em;
  }
}

.page_box {
  width: 100vw;
  height: auto;
  border-bottom: 1px solid;
  border-color: #deb992;
}

.draw_line {
  border-bottom: 1px solid;
  border-color: #1ba098;
}

.draw_line_gold {
  border-bottom: 1px solid;
  border-color: #deb992;
  margin-left: 10vw;
  margin-right: 10vw;
}

.pull_right {
  float: right;
}

.field_heading {
  padding-bottom: 2px;
}

.field_input {
  padding: 5px;
  width: 100%;
  margin-bottom: 5px;
}

.field_input_h {
  display: none;
}

.field_heading_h {
  display: none;
}

.error {
  border: 2px solid;
  border-color: red;
}

.field_submit {
  background-color: #1ba098;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
}

.blog_table_button {
  background-color: #1ba098;
  border-radius: 5px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
}

.menu_toggle {
  background-color: #deb992;
  border-radius: 0px 0px 5px 0px;
  border: none;
  color: #051622;
  padding: 15px 32px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  min-width: 100px;
}

.table-bordered > thead > tr > th{
  border: 1px solid #1ba098;
  font-size: 1.2em;
}

.table-bordered > tbody > tr > td{
  border: 1px solid #1ba098;
}
